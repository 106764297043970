<template>
  <div>
    <p class="h4 pt-3 pb-4">发放积分(工会)</p>
    <div class="mb-3">
      <!-- <el-button type="success" @click="addAllUser()">批量添加</el-button> -->
      <el-button type="primary" @click="addTeam()">添加工会</el-button>
      <!-- <el-button type="primary" @click="rewardFirst()" v-show="tableData.length > 0">发放积分</el-button> -->
    </div>
    <div class="issuc-table">
      <el-table
        :data="tableData"
        style="width:100%">
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          label="工会id">
          <template slot-scope="scope">
            <div style="height:45px;">
              <el-input v-model="tableData[scope.$index].teamid" placeholder="工会id" size="mini"></el-input>
              <div class="issue-err">{{validID(scope.row.teamid, '工会id')}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="projectid">
          <template slot-scope="scope">
            <div style="height:45px;">
              <el-input v-model="tableData[scope.$index].projectid" placeholder="projectid" size="mini"></el-input>
              <div class="issue-err">{{validID(scope.row.projectid, 'projectid')}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="积分">
          <template slot-scope="scope">
            <div style="height:45px;">
              <el-input v-model="tableData[scope.$index].points" placeholder="积分数量" size="mini"></el-input>
              <div class="issue-err">{{validPonit(scope.row.points)}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="complete(scope.row, scope.$index)">发送积分</el-button>
            <!-- <el-button class="ml-0 issuc-edit" type="text" size="small"  icon="el-icon-edit-outline" @click="editUser(scope.$index)" v-show="!scope.row.show"></el-button> -->
            <el-button type="danger" size="small" @click="delTeam(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <el-dialog
      title="提示"
      :visible.sync="addAllVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <div class="mb-3">
          <span class="btn btn-primary btn-sm fileinput-button">
            <span>选择文件</span>
            <input type="file" name="xlfile" ref="xlf" @change="handleFile">
          </span>
          <a class="ml-3" href="./static/files/批量发送积分.xlsx">下载工会模板</a>
        </div>
        <el-checkbox v-model="resetUsers">清空现有工会</el-checkbox>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
// import XLSX from 'xlsx'
import { rewardPointsTeam } from '@/api/points'
export default {
  name: 'sendteam',
  data () {
    return {
      tableData: []
      // addAllVisible: false,
      // resetUsers: false
    }
  },
  computed: {
    validID () {
      return (id, msg) => {
        let res = ''
        // 存在时，整数数字校验
        if (id) {
          const type = Number(id)
          if (isNaN(Number(type)) || id.toString().indexOf('.') !== -1) {
            res = `${msg}格式错误`
          }
        } else {
          res = `${msg}不能为空`
        }
        return res
      }
    },
    validPonit () {
      return point => {
        let res = ''
        // 存在时，整数数字校验
        if (point) {
          const type = Number(point)
          if (isNaN(Number(type)) || point.toString().indexOf('.') !== -1 || point.toString()[0] === '0') {
            res = '积分应为大于0的整数数字'
          }
        }
        return res
      }
    }
  },
  methods: {
    addTeam () {
      this.tableData.push({
        teamid: '',
        projectid: '',
        points: 0
      })
    },
    // 删除工会
    delTeam (index) {
      this.tableData.splice(index, 1)
    },
    // 完成校验
    complete (row, index) {
      const { teamid, projectid, points } = row
      // 为空提示
      if (!teamid || !points || !projectid) {
        this.$notify.error({
          title: '错误',
          message: '请将信息填写完整'
        })
        return
      }
      // 验证信息错误提示
      if (this.validPonit(points)) {
        this.$notify.error({
          title: '错误',
          message: '请正确填写信息'
        })
        return
      }
      // 验证通过，填写备注
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then((res) => {
        const msg = res.value
        // 填写发送密码
        this.$prompt('请输入积分密码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\w/,
          inputErrorMessage: '积分密码不能为空'
        }).then(({ value }) => {
          this.rewardSecond(msg, value, teamid, projectid, points, index)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消发送'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消发送'
        })
      })
    },
    rewardSecond (msg, password, teamid, projectid, points, index) {
      // 请求接口
      rewardPointsTeam({
        checksum: password,
        projectid: Number(projectid),
        msg: msg || '',
        teamid: Number(teamid),
        points: Number(points)
      }).then(
        res => {
          // 发放成功提示
          // 点击确认后再删除
          this.$alert('积分发送成功', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.delTeam(index)
            }
          })
        }
      ).catch(
        err => {
          console.log(err)
        }
      )
    }
  }
}
</script>
<style>
.issuc-table {
  max-width: 1000px;
  border: 1px solid #EBEEF5;
  padding: 24px;
}
.issue-err {
  color: #F56C6C;
  font-size: 12px;
  line-height: 1.5;
  text-indent: 5px;
}
/* .issuc-edit {
  font-size: 14px;
  padding: 0;
}
.issuc-edit:focus, .issuc-edit:active {
  outline: none;
} */
.fileinput-button {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.fileinput-button input {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  /* -ms-filter: "alpha(opacity=0)"; */
}
</style>
